* {
  margin: 0px;
  padding: 0px;
}

.normal {
  display: flex;
  padding: 5px;
  font-size: 25px;
  font-family: "Helvetica Neue";
  color: white;
}

.banner {
  transition: 0.15s;
}

.banner:hover {
  background-color: #ad92d5;
  scale: 1.05;
}

.banner:active {
  transform: rotate(2deg);
}

.info {
  display: flex;
  flex-direction: column;

  width: 400px;
  height: 500px;

  transition: 0.4s;
}

.info:hover {
  scale: 1.05;
}

.spacer {
  aspect-ratio: 900/255;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 50;
  border: none;
}
.header {
  aspect-ratio: 900/150;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.flip {
  transform: rotate(180deg);
}

.stack1 {
  background-image: url("../images/bg_images/stacked-waves-haikei.svg");
}
.peak1 {
  background-image: url("../images/bg_images/layered-peaks-haikei.svg");
}
.peak2 {
  background-image: url("../images/bg_images/sponsors_flip.svg");
}
.layer1 {
  background-image: url("../images/bg_images/layered-waves-haikei.svg");
}
.layer2 {
  background-image: url("../images/bg_images/layered-waves-haikei-flipped.svg");
}

.material {
  aspect-ratio: 1000/450;
  width: 100%;
  background-image: url("../images/bg_images/notes.PNG");
  background-position: center;
  background-size: cover;
}

.lights {
  aspect-ratio: 1100/600;
  width: 100%;
}

.blur-bg {
  /* background-color: rgba(2, 1, 4, 0.5);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px); */

  width: 100vw;
}

.ted-color {
  color: #ec042b !important;
}

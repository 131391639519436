* {
  margin: 0px;
  padding: 0px;
}

.countdown {
  font-size: 40px;
  color: white;
  box-shadow: 1;
  text-shadow: 2px 2px #000000;
}
.time {
  display: flex;
  padding: 5px;
  font-size: 25px;
  color: white;
}
.cormorant-garamond-semibold {
  font-family: "Cormorant Garamond", serif;
  font-weight: 600;
  font-style: normal;
}
.cormorant-garamond-bold {
  font-family: "Cormorant Garamond", serif;
  font-weight: 800;
  font-style: normal;
}
.date {
  display: flex;

  padding: 10px;
  align-items: center;
  justify-content: center;
}

.title {
  width: 45%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 300px 100px;
}
